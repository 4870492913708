<template>
	<div :class="{'card bg-light':!(tipo=='auto-compras-nuevo' && isMobile)}">
		<div :class="{'card-body':!(tipo=='auto-compras-nuevo' && isMobile)}">
			<h2 class="text-center" v-if="tipo!='auto-compras-nuevo'">Cotizador</h2>
			<p v-if="tipo!='auto-compras-nuevo'">Aquí puedes hacerte una idea de la cuota que podrías llegar a pagar (*Recuerda que no existen castigos o multas por pagar antes de tiempo)</p>

			<slot></slot>

			<div class="form-row align-items-center">
				<div class="col-lg-7" v-if="tipo!='auto-compras-nuevo'">
					<div class="card bg-light border mb-2">
						<div class="card-body text-left">
							<div class="mb-3">
								<div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
									<label>{{title}}:</label>
									<div class="mb-1">
										<CurrencyInput v-model="monto" type="text" required name="Monto" placeholder="Ingrese monto" class="form-control border" />
									</div>
								</div>
							</div>
							<div class="mb-3">
								<div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
									<label>Plazo (meses):</label>
									<div class="mb-1">
										<input type="number" v-model="cotizacion.plazo" class="form-control border" :min="1" :max="maxPlazo" step="1">
									</div>
									<p class="font-weight-bold" v-if="false">
										{{cotizacion.plazo}} meses
									</p>
								</div>
								<Slider v-model="cotizacion.plazo" :step="1" :min="1" :max="maxPlazo" :tooltips="false" />
							</div>
							<div class="mb-3" v-if="engancheDF">
								<div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
									<label>Enganche ({{enganchePorc}}%)</label>
									<div class="mb-1">
										<CurrencyInput v-model="engancheInput" type="text" name="monto" placeholder="Ingrese monto" class="form-control border" />
									</div>
									<p class="font-weight-bold" v-if="false">
										{{$filters.currency(cotizacion.enganche)}}
									</p>
								</div>
								<Slider v-model="cotizacion.enganche" :min="engancheMin" :max="monto" :tooltips="false" v-if="monto>0" />

								<span v-if="message" class="text-danger">
									{{message}}
								</span>
							</div>

							<div v-if="tipo == 'auto-compras' || tipo == 'vehiculos'" class="mb-3">
								<div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
									<label class="flex-fill">
										Utilización del vehículo
									</label>
									<div>
										<select class="custom-select" v-model="cotizacion.utilizacion">
											<option value="seguro_particular">Particular</option>
											<option value="seguro_uber">UBER</option>
											<option value="seguro_bus_20">Bus hasta 20 pasajeros</option>
											<option value="seguro_bus_21-35">Bus 21-35 pasajeros</option>
											<option value="seguro_bus_35">Bus mas de 35 pasajeros</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center mb-3">
						NO existen cobros o multas por pagar tu crédito antes de tiempo.
					</div>
				</div>
				<div :class="{'col-lg-12':tipo=='auto-compras-nuevo', 'col-lg-5':tipo!='auto-compras-nuevo'}">
					<div :class="{'card':!(tipo=='auto-compras-nuevo' && isMobile)}">
						<div :class="{'card-body':!(tipo=='auto-compras-nuevo' && isMobile)}">
							<div class="p-3 text-center" v-if="logoDF">
								<img v-lazy="logoDF" class="img-fluid w-75" :alt="tipo">
							</div>
							<div class="p-3 text-center" v-else>
								<img src="@/assets/image/logo_dark.webp" class="img-fluid w-75" alt="Logo Cash In">
								<p class="mb-0"><strong>www.clubcashin.com</strong></p>
							</div>

							<div class="card mb-3">
								<div class="card-body">
									<div v-if="false">
										<div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mb-3" v-if="showVehiculoDF">
											<span>Cuota fija</span>
											<strong>{{$filters.currency(cuota)}}</strong>
										</div>

										<div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mb-3" v-if="showVehiculoDF">
											<span>Costo del seguro</span>
											<strong>{{$filters.currency(cotizacion.seguro)}}</strong>
										</div>

										<div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mb-3 border-bottom" v-if="showVehiculoDF">
											<span>Costo del GPS</span>
											<strong>{{$filters.currency(cotizacion.gps)}}</strong>
										</div>
									</div>

									<div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mb-3">
										<span>Cuota mensual</span>
										<strong>{{$filters.currency(total)}}</strong>
									</div>

									<div class="text-muted font-weight-bold">
										<small class="font-weight-bold">*Tu cuota incluye seguro y GPS</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Slider = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Slider" */"@vueform/slider"),
		loadingComponent: {template:`<loading-component />`}
	})

	const datosSeguros = require('@/assets/datos.json')

	export default {
		props: ['modelValue', 'tipo', 'logo', 'montoEnganche', 'montoPlazo'],
		components: {
			CurrencyInput,
			Slider
		},
		data(){
			return {
				monto: 1,
				message: '',
				cotizacion: {
					plazo: 12,
					enganche: 0,
					gps: 0,
					seguro: 0,
					utilizacion: 'seguro_particular'
				},
				engancheInput: 0,
				tipoCuota: 'nivelada',
			}
		},
		mounted(){
			this.monto = parseFloat(this.modelValue || 0)
			this.cotizacion.enganche = parseFloat(this.engancheMonto || 0)
			this.engancheInput = this.cotizacion.enganche || 0
			this.cotizacion.plazo = parseFloat(this.plazoMonto || this.maxPlazo)
			this.getSeguro()
			this.getGPS()

			this.$emit('maxPlazo', this.maxPlazo)
		},
		methods: {
			getSeguro(){
				var monto = this.monto || 0
				/*if (this.tipo == 'vehiculos') {
					monto = (monto || 0) * 2
				}*/
				if (!this.showVehiculoDF) {
					this.cotizacion.seguro = 0
					return
				}
				var dd = this.seguros.filter(x => parseFloat((x.monto || '0').replace(/[,]/g, '')) <= parseFloat(monto || 0))
				var first = dd[dd.length-1] || {}

				var seguroMonto = 0

				if(this.tipo == 'auto-compras' || this.tipo == 'vehiculos'){
					seguroMonto = first[this.cotizacion.utilizacion] || 0
				}else{
					seguroMonto = first.seguro_nuevo || 0
				}

				this.cotizacion.seguro = parseFloat((seguroMonto || '0').replace(/[,]/g, ''))
			},
			getGPS(){
				if (!this.showVehiculoDF) {
					this.cotizacion.gps = 0
					return
				}
				this.cotizacion.gps = parseFloat((datosSeguros.gps || '0').replace(/[,]/g, ''))
			},
			validateEnganche(){
				if (this.engancheInput>=this.engancheMin && this.engancheInput<=this.monto) {
					this.cotizacion.enganche = this.engancheInput || 0
					this.message = ''
				}else{
					if (this.engancheInput>this.monto) {
						this.message = 'El valor es mayor al monto del crédito'
					}else{
						this.message = 'El valor es menor al enganche mínimo'
					}
				}
			}
		},
		computed: {
			seguros(){
				return datosSeguros.seguros || []
			},

			isMobile() {
				return this.$store.getters.isMobile
			},
			enganchePorc(){
				return (((this.cotizacion.enganche) / (this.monto || 1)*100)).toFixed(2)
			},
			total(){
				return (this.cotizacion.seguro || 0) + (this.cotizacion.gps || 0) + (this.cuota || 0)
			},
			cuota(){
				var d = ((this.credito || 0) * (this.interes || 0))
				var e = (1-Math.pow((1+this.interes), ((this.cotizacion.plazo || 0)*-1)))
				return d/e
			},
			credito(){
				if (this.engancheDF) {
					return ((this.monto || 0)-(this.cotizacion.enganche || 0))
				}
				return (this.monto || 0)
			},
			fijo(){
				return (this.monto || 0) * this.interes
			},
			interesTipo(){
				return datosSeguros.interes[this.tipo] || '0'
			},
			interes(){
				return parseFloat((this.interesTipo).replace(/[,]/g, '')) * 1.12
			},
			showVehiculoDF(){
				return this.tipo == 'vehiculos' || this.tipo == 'auto-compras' || this.tipo == 'auto-compras-nuevo' || this.tipo == 'auto-compras-vehiculo'
			},
			engancheDF(){
				return this.tipo == 'auto-compras' || this.tipo == 'auto-compras-nuevo' || this.tipo == 'auto-compras-vehiculo'
			},
			title(){
				if (this.tipo == 'auto-compras' || this.tipo == 'auto-compras-nuevo' || this.tipo == 'auto-compras-vehiculo') {
					return 'Valor del carro'
				}
				return 'Monto del crédito'
			},
			query(){
				return this.$route.query || {}
			},
			engancheMonto(){
				return this.query.enganche || 0
			},
			plazoMonto(){
				return this.query.plazo || 0
			},
			maxPlazo(){
				if (this.tipo == 'vehiculos' || this.tipo == 'auto-compras' || this.tipo == 'auto-compras-vehiculo') {
					return 60
				}
				return 84
			},
			engancheMin(){
				if (this.engancheDF) {
					return parseFloat(((this.monto || 0) * (10/100.00)).toFixed(2))
				}
				return 0
			},
			enganche(){
				return this.cotizacion.enganche
			},
			utilizacion(){
				return this.cotizacion.utilizacion
			},
			logoDF(){
				return this.logo
			}
		},
		watch: {
			engancheMonto(val){
				this.cotizacion.enganche = parseFloat(val || 0)
			},
			plazoMonto(val){
				this.cotizacion.plazo = parseFloat(val || 12)
			},
			/*credito(val){
				if (this.tipo == 'auto-compras') {
					this.$emit('update:modelValue', val)
				}
			},*/
			monto(val){
				this.getSeguro()
				this.$emit('update:modelValue', val)
			},
			utilizacion(val){
				this.getSeguro()
				return val
			},
			modelValue(val){
				this.monto = parseFloat(val || 0)
				if (this.tipo == 'auto-compras' || this.tipo == 'auto-compras-nuevo') {
					this.validateEnganche()
				}
			},
			engancheMin(val){
				//if (val>this.cotizacion.enganche) {
					this.$nextTick(() => {
						this.cotizacion.enganche = val || 0
						this.engancheInput = val || 0
					})
				//}
			},
			enganche(val){
				this.engancheInput = val
				this.$emit('enganche', val)
			},
			maxPlazo(val){
				this.$emit('maxPlazo', val)
			},
			engancheInput(val){
				this.validateEnganche()
				return val
			},
			montoEnganche(val){
				this.cotizacion.enganche = val
			},
			montoPlazo(val){
				this.cotizacion.plazo = val
			}
		}
	}
</script>